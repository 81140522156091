/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */

import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

interface HelmetProps {
  title: string;
  description?: string;
  pathname: string;
  image?: string;
  location?: string;
  canonical?: string;
  published?: string;
  timeToRead?: string;
}

const seoQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            description
            social {
              url
            }
            siteUrl
            title
          }
        }
      }
    }
  }
`;

const themeUIDarkModeWorkaroundScript = [
  {
    type: 'text/javascript',
    innerHTML: `
    (function() {
      try {
        var mode = localStorage.getItem('theme-ui-color-mode');
        if (!mode) {
          localStorage.setItem('theme-ui-color-mode', 'light');
        }
      } catch (e) {}
    })();
  `,
  },
];



const SEO: React.FC<HelmetProps> = ({
  title,
  description,
  children,
  url,
  image,
  published,
  pathname,
  timeToRead,
}) => {
  const results = useStaticQuery(seoQuery);
  const site = results.allSite.edges[0].node.siteMetadata;
  const twitter = site.social.find(option => option.name === 'twitter') || {};
  const fullURL = (path: string) =>
    path ? `${site.siteUrl}${path}` : site.siteUrl;

  // If no image is provided lets looks for a default novela static image
  image = image ? image : '/preview.jpg';
  

  const metaTags = [
    { charset: 'utf-8' },
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      name: 'theme-color',
      content: '#fff',
    },
    {
      rel: 'canonical',
      href: fullURL(pathname),
    },
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description || site.description },
    { itemprop: 'image', content: fullURL(image) },
    { name: 'description', content: description || site.description },
    { name: 'keywords', content: "EOS Blockchain, Nem Blockchain, Stellar Lumen, XEM, Bitcoin, Tezos, Ethereum, TypeScript, React, NodeJS, Gatsby, Python, FullStack, Deep Learning, Machine Learning, Travel, Opinion" },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: pathname + '/index.html' },
    { name: 'twitter:title', content: title || site.title },
    { name: 'twitter:description', content: description || site.description },
    { name: 'twitter:creator', content: "@fonsecaj89"},
    {
      name: 'twitter:image',
      content: fullURL(image),
    },

    { property: 'og:title', content: title || site.title },
    { property: 'og:url', content: pathname + '/index.html' },
    { property: 'og:image', content: fullURL(image) },
    { property: 'og:description', content: description || site.description },
    { property: 'og:site_name', content: site.name },
    { property: 'fb:pages', content: "1637937216497855" },
  ];

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published });
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' });
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` });
  }

  return (
    <Helmet
      title={title || site.title}
      htmlAttributes={{ lang: 'en' }}
      script={themeUIDarkModeWorkaroundScript}
      meta={metaTags}
    >
        <script data-ad-client="ca-pub-2571185593289382" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <script async src="https://platform.twitter.com/widgets.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-133686513-1"></script>
        <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-133686513-1');`}
        </script>

        <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '635333076952680');
            fbq('track', 'PageView'); `}
        </script>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v5.0&appId=610355006444634&autoLogAppEvents=1"></script>    
        <noscript>
            {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=635333076952680&ev=PageView&noscript=1"/>`}
        </noscript>

      <link
        href="https://fonts.googleapis.com/css?family=Merriweather:700,700i&display=swap"
        rel="stylesheet"
      />

      <script>
      {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-133686513-1', { 'optimize_id': 'GTM-KFX2SCN'});
      `}</script>


      <script>
        {`window.fbAsyncInit = function() {
          FB.init({
            xfbml            : true,
            version          : 'v5.0'
          });
        };

        (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));`}</script>

      <div className="fb-customerchat"
        attribution="setup_tool"
        page_id="1637937216497855"
  logged_in_greeting="Gracias por su visita, ¿En que puedo ayudarte? "
  logged_out_greeting="Gracias por su visita, ¿En que puedo ayudarte? ">
      </div>
      {children}
    </Helmet>
  );
};

export default SEO;
import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 30 30"
    xmlSpace="preserve"
  >
      <circle cx="15" cy="15" r="20" >
            <image
            width="30"
            height="30"
            x="0"
            y="0"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo AAB1MAAA6mAAADqYAAAXcJy6UTwAAADwUExURSouMSwtMSwtLysvMiorLfb3+fT19/P09vHy9vb3 +/Dx9SssMCktMC0xNCgsLyYqLff4+igpKykqLCssLi0uMC0uMvX2+vj5/TA0NyMnKi4yNfn6/Ds8 PiUmKC8wMjM0Nvf4/CorLycoLDAxNfL2+ScrLu/w8jEyNC4vM/X2+DAxMycoKi8wNPn9/yImKfHy 9DIzNfj5+ykqLvD09y4vMYGChvP3+kFFSPLz9e3x9EhMTywwM/n6/iYnKyUpLPT4+yQoK/Lz9zEy NvT1+fH1+PP0+DM0ODIzNy8zNvX5/Pz9/+3u8khJTUFCRigpLf///18cgqMAAAABYktHRE9uZkFJ AAAACXBIWXMAAABIAAAASABGyWs+AAAAB3RJTUUH5AETBh8iInoRPQAAAHd0RVh0UmF3IHByb2Zp bGUgdHlwZSA4YmltAAo4YmltCiAgICAgIDQwCjM4NDI0OTRkMDQwNDAwMDAwMDAwMDAwMDM4NDI0 OTRkMDQyNTAwMDAwMDAwMDAxMGQ0MWQ4Y2Q5OGYwMGIyMDRlOTgwMDk5OAplY2Y4NDI3ZQqmU8OO AAAAa3pUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAAiZ40qtyEzjUgADcxMuE1NzCzNLMzMDIDBJ MUkxMDBKNIAACyA2tDA3swRJQcUMoRRMDQgYAzmGXCAGkiKwQqCEERbNqYkQtRjiIJILTAAAAW4h H6sfwY8AAAAldEVYdFJhdyBwcm9maWxlIHR5cGUgaXB0YwAKaXB0YwogICAgICAgMArA1vxmAAAB IElEQVQoz82R2VLCQBBFZ6YJCcxkgiQTFiUayUBi4hYQl0jYxf3/P0defOkqtcoHy/N6qm/17SYE QXeQr/m3mrFfaqgYRtUwrVqdC7Altk7D2Gu6nqJ+y2l3ujh2/6AXHFa8o/C4H2kQSBsDNxj2PLPu xzyKujg8OUmHLKiaKuOxPD3D4ecXTeaml4nKKYeRrVGhajp2A2+QXCmuyORaIG2MmctY40ZRnsnb uwKd8n6qMprX/LJNhJzNbaQXSxpOs8Wq3bFldw0FWs3a0Aff9zkptBhFgIuFm21IF4oLSYqZQ/B0 3F/Vl4pS+bh+IiBK/OecPr+srNYsmmgAYiNdQBhu+etbB2B3cNBIC6Li94wvAWRR2hpPfxYk3/GD /lM+AJDAG85fPKFMAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTE5VDA2OjMxOjM0KzAzOjAw PxN9ywAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0xOVQwNjozMTozNCswMzowME5OxXcAAAAR dEVYdGV4aWY6Q29sb3JTcGFjZQAxD5sCSQAAABd0RVh0ZXhpZjpFeGlmSW1hZ2VMZW5ndGgAMzCK /G6AAAAAFnRFWHRleGlmOkV4aWZJbWFnZVdpZHRoADMwxw7TBQAAABJ0RVh0ZXhpZjpFeGlmT2Zm c2V0ADI2UxuiZQAAAABJRU5ErkJggg=="
        ></image>
      </circle>
    </svg>
  );
}
